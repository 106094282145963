$enable-ltr: true;
$enable-rtl: true;
@font-face {
  font-family: "Felix Titling";
  src: url("../fonts/felix-titling-font/Felix\ Titling.ttf") format("woff2");
  /* Add other font properties if needed */
}
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Hahmlet");
@import url("https://fonts.googleapis.com/css?family=Elsie");
// @import url("https://fonts.googleapis.com/css?family=Felix Titling");
// import cutomer suport scss file
@import "customer_support.scss";

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body.modal-open {
  padding-right: 0px !important;
  // margin-right: 1rem;
}

* {
  box-sizing: border-box;
  // overflow: hidden;
  // overflow-x: hidden;
  margin: 0;
  padding: 0;
}
body,
html {
  background: #e1f3ff;
  width: 100%;
  box-sizing: border-box;
  // overflow: hidden;
  // overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.main {
  margin: 20px;
}
@media (max-width: 768px) {
  .form-control {
    width: 100%; /* Make inputs full width on smaller screens */
  }
}

.container2 {
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 3.4rem;
}

@media screen and (max-width: 768px) {
  .container2 {
    margin: 0;
  }

  .main {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .carousel-text {
    width: 40%;
  }
}

.custom-button {
  padding: 8px;
  background: transparent;
  border: 2px solid white;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  transition: "background 0.3s";
}
.custom-button5 {
  padding: 8px;
  background: transparent;
  border: 2px solid white;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0rem 1rem;
  transition: "background 0.3s";
}
.custom-button1 {
  padding: 8px;
  background: #285979;
  border: 2px solid #285979;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
  transition: "background 0.3s";
}

.custom-button:hover {
  background-color: #285979;
  border: 2px solid #285979;

  /* Blue color for hover effect */
  color: #fff; /* Text color on hover */
}
.custom-button5:hover {
  background-color: #285979;
  border: 2px solid #285979;

  /* Blue color for hover effect */
  color: #fff; /* Text color on hover */
}
.section {
  width: 300px;
  height: 300px;
  margin: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.card-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
  padding-left: 1%;
  padding-right: 1%;
}

.container-title {
  font-family: "Raleway";
  font-style: normal;
  color: #000;
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  // text-align: center;

  // padding-top: 6rem;
  margin-bottom: 3rem;
}

// .container-title small screen
// @media only screen and (max-width: 390px) {
//   .container-title {
//     font-size: 30px;
//   }
// }

// .container-title medium screen
// @media only screen and (max-width: 768px) {
//   .container-title {
//     font-size: 35px;
//   }
// }

.small {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000;
}

.text-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;
}

.text-wrapper {
  /// position: absolute; /* Position text wrapper */
  //top: 50%; /* Center vertically */
  //transform: translateY(-50%); /* Adjust vertical position */
  text-align: center; /* Center horizontally */
  padding: 0 20px; /* Add some padding */
  height: 100%; /* Take full height of column */
  width: 100%; /* Take full width of column */
  vertical-align: middle;
  align-items: center;
  // z-index: 1; /* Add a z-index to make sure this text is above the image */
}

@media screen and (max-width: 768px) {
  .text-wrapper {
    position: relative;
    top: 0;
    transform: translateY(0);
  }
}

// .col-sm img {
//   width: 100%; /* Make the image take the full width */
//   // height: auto; /* Maintain the aspect ratio */
//   // position: relative;
//   // z-index: 0; /* Send the image to the back */
// }

.list_container {
  direction: rtl;
  overflow: auto;
  height: 50px;
  width: 50px;
}

.item_direction {
  direction: rtl;
}

// @media only screen and (max-width: 320px) {
//   .container-title {
//     font-size: 2em;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .container-title {
//     font-size: 3em;
//   }
// }
.collectionTitle {
  color: #285979;
  text-align: center;
  font-family: "Raleway";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem;
}
.divider {
  margin-bottom: -1.1rem;
}
.divider2 {
  margin-bottom: -1rem;
}

.title {
  font-family: "Felix Titling";
  font-size: 50px;
  font-weight: 350;
  // line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #285979;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.followUs {
  font-family: "Felix Titling";
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #285979;
  // margin-bottom: 1rem;
}
.chaticon {
  width: 50px;
}
@media screen and (max-width: 600px) {
  .container-title {
    font-size: 2.3rem;
  }
  .followUs {
    font-family: "Felix Titling";
    font-size: 20px;
    font-weight: 600;
    // line-height: 60px;
    letter-spacing: 0em;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #285979; /* Set font size to 20px for small screens */
  }
  .br {
    display: none;
  }
  .custom-button5 {
    padding: 8px;
    background: transparent;
    border: 1px solid white;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    padding: 0rem 1rem;
    transition: "background 0.3s";
  }
  .custom-button1 {
    padding: 4px;
    background: #285979;
    border: 2px solid #285979;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: bold;
    transition: "background 0.3s";
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    margin-left: 25%;
  }

  .title {
    font-family: "Felix Titling";
    font-size: 18px;
    // font-weight: 350;
    // line-height: 60px;
    letter-spacing: 0em;
    text-align: center;
    font-weight: 600;
    color: #285979;
    display: flex;

    // justify-content: center;
    max-height: 30px;
    align-items: center;
  }
  .chaticon {
    width: 40px;
  }
  .divider {
    margin-bottom: -1.2rem;
  }
  .divider2 {
    margin-bottom: -1.2rem;
  }
  .collectionTitle {
    color: #285979;
    text-align: center;
    font-family: "Raleway";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 1rem;
  }
}

.container {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;

  padding: 0%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container a {
  text-decoration: none;
  color: black;
}

.grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-gap: 20px;
}

.grid-about {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
}

@media screen and (max-width: 1000px) {
  .grid-about {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 20px;
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.item {
  justify-content: center;

  display: flex;
  align-items: center;
  height: auto;
}
.item img {
  width: 100%;
}
.item h3 {
  padding: 1% 5%;
  color: grey;
  width: 100%;
  height: auto;
}

.product-button {
  font-family: "Raleway";
  background-color: #285979;
  display: block;
  width: 100%;
  border-radius: 0;
  cursor: pointer;
  padding: 0.5rem;
}

.btn-submit {
  font-family: "Raleway";
  background-color: #4f1e1a;
  display: block;
  width: 100px;
  border-radius: 20px;
  cursor: pointer;
  padding: 0.5rem;
  color: white;
  border-radius: 4%;
  border: none;
}

.btn-submit:hover {
  background-color: #4f1e1a73;
  color: #4f1e1a;
}

.card-icon {
  margin-left: auto;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@media all and (min-width: 650px) {
  .container {
    width: 100%;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: min-content;
    grid-gap: 10px;
  }
}

@media all and (min-width: 900px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

@media only screen and (max-width: 576px) {
  .carousel-home {
    height: calc(var(--vh, 1vh) * 50);
  }
}

@media only screen and (max-width: 768px) {
  .carousel-home {
    height: calc(var(--vh, 1vh) * 60);
  }
}

@media only screen and (max-width: 992px) {
  .carousel-home {
    height: calc(var(--vh, 1vh) * 70);
  }
}

.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 9px !important; /* default to 10px */
}

/* Small screens */
@media screen and (min-width: 320px) {
  .responsive-calendar {
    font-size: 7px !important;
  }
}

@media (min-width: 576px) {
  .responsive-calendar {
    font-size: 9px !important;
  }
}

@media (min-width: 768px) {
  .responsive-calendar {
    font-size: 10px !important;
  }
}

@media (min-width: 1200px) {
  .responsive-calendar {
    font-size: 12px !important;
  }
}

@media (min-width: 1500px) {
  .responsive-calendar {
    font-size: 14px !important;
  }
}

/* Large screens */
@media (min-width: 2500px) {
  .responsive-calendar {
    font-size: 17px !important;
  }
}

.scaleonhover {
  transition: all 0.2s ease-in-out;
}

.scaleonhover:hover {
  transform: scale(1.1);
}

.shadow-box:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.custom-border {
  position: relative;
  /*width: 200px;  Adjust the width as needed */
  /*height: 100px;  Adjust the height as needed */
  border: none;
  padding: 0;
  margin: 0;
}

.custom-border::before,
.custom-border::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 0;
  border-style: solid;
}

.custom-border::before {
  top: 0;
  right: 0;
  border-color: #285979;
  border-width: 8px 0 0 0; /* top border */
}

.custom-border::after {
  bottom: 0;
  left: 0;
  border-color: #285979;
  border-width: 0 0 8px 0; /* bottom border */
}

.savings-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;

  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .savings-container {
    grid-template-columns: 1fr;
  }
}

.range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.range-slider:hover {
  opacity: 1;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #285979;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #285979;
  cursor: pointer;
}

/* basic positioning */
.legend {
  list-style: none;
}
.legend li {
  float: left;
  margin-right: 10px;
}
.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px;
}
/* your colors */
.legend .paynow {
  background-color: #285979;
  margin-top: 0.4rem;
}
.legend .discounted {
  background-color: #e1f3ff;
  margin-top: 0.4rem;
}

.primary-text {
  color: #285979;
}

.accordion {
  --bs-accordion-active-bg: purple;
}

$accordion-button-active-color: grey;
$accordion-button-focus-box-shadow: 0 0 0 0.25rem brown;
.accordion-body {
  background-color: #edecec;
}
.accordion-button:not(.collapsed) {
  color: #285979;
  background-color: #d4d8d8;
}

.our-legacy {
  // background-color: #4f1e1a;
  background: #c8c2bd;
}
.swiper {
  width: 100%;
  // padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
// @media (max-width: 668px) {
//   .our-legacy {
//     // padding-top: 10rem;
//   }
// }
