@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Hahmlet");

.main-footer {
  color: #ffffff;
  background-color: #285979;
  background-image: url("../AlIimage/footer.png");
  padding-top: 2em;
  position: relative;
  padding-bottom: 2%;
  bottom: 0;
  width: 100%;
  /* background-color: rgb(46, 142, 225); */
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #ffdd40;
}

.shopname {
  font-family: "Hahmlet";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
}

.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  /* padding: 2rem; */
  font-family: "Hahmlet";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 2% !important;
  padding-bottom: 0% !important;
  width: 100%;

  margin-bottom: 0;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-right: 10% !important;
}

.social-icons li {
  margin: 0 1em;
}

.social-icons li a {
  color: rgb(0, 0, 0);
}

.social-icons li a:hover {
  color: brown;
}

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1em;
  width: 100%;
}
