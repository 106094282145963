@import url("https://fonts.googleapis.com/css?family=Raleway");
.navbar {
  background-color: #285979;
  height: 75px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  max-width: 100%;
  z-index: 20;
  transition-property: all;
  opacity: 1;
  padding-left: 1rem;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.navbar.down {
  top: -5rem;
}

.nav-container {
  display: flex;
  padding-left: 0px;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.nav-logo {
  cursor: pointer;
  text-decoration: none;
  width: 100px;
}

.nav-menu {
  display: flex;
  color: #ffffff;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  flex-grow: 1; /* Add this line to make the menu take up available space */
}

.nav-links {
  color: #ffffff;
  text-decoration: none;
  font-family: Raleway;
  padding: 0.55rem 1rem;
  height: 70%;
  font-weight: 600;
  text-transform: uppercase;
  /* border-bottom: 3px solid transparent; */
}

.fa-code {
  margin-left: 0rem;
}

.nav-item {
  line-height: 40px;
  color: #ffffff;
  margin-right: 1 rem;
}
.nav-item1 {
  line-height: 40px;
  color: #ffffff;
  margin-right: 1 rem;
}

.nav-item-right {
  line-height: 40px;
  color: #ffffff;
  margin-left: 1 rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #fff;
}

.nav-item .active {
  /* color: #df6c4f; */

  border-bottom: 2px solid #fff;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    border-top: 1px solid #fff;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #285979;
    left: 0;
    list-style: none;
    padding: 1rem;
    margin: 0;

    margin-right: "1rem";
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
  .nav-item1 .active {
    color: #fff;
    /* border-bottom: 2px solid #fff; */
  }

  .nav-links {
    padding: 0.8rem;
    width: 100%;
    font-family: Raleway;
    display: table;
  }

  .nav-icon {
    display: block;

    position: absolute;
    top: 0;
    right: 0;
    margin-top: -3%;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
}
