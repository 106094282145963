.customer-support-icon {
  position: fixed;
  bottom: 4%;
  right: 20px;
  margin: 20px;
  z-index: 2;
  // background: #fff;
  border-radius: 50%;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // border: 1px solid #ccc;
  width: 2%;
  height: auto;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-100w {
  // width: 20px;
  // max-width: 200%;
  height: auto;

  // max-height: 90%;
  margin: 0 auto;
  position: fixed;
  bottom: 4%;
  right: 20px;
  // margin: 20px;
  z-index: 2;
}

.center-column {
  display: flex;
  justify-content: center;
  // create margin between items

  align-items: center;
  color: black;
}

.modal-content {
  // padding-bottom: 5%;
  background: #fff;
}

// import image customer-support.svg

// set icon as background for image
.btnClose {
  background: url("https://img.icons8.com/ios/50/FFFFFF/delete-sign--v1.png")
    no-repeat;
  background-size: contain;

  border: none;
  cursor: pointer;
  outline: none;
}
.door-modal .modal-dialog {
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
}

.door-modal.show .modal-dialog {
  transform: translateY(0);
}
